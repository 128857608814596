import getConfig from 'next/config';
import { GetServerSidePropsContext } from 'next';
import { DocumentNode } from 'graphql/language/ast';
import { ApolloQueryResult, NormalizedCacheObject } from '@apollo/client';
import { getApollo } from '@/graphql/apolloClient';
import {
  BREAKING_NEWS,
  FOOTER_LOCALIZED,
  FOOTER_NATIONAL,
  HEADER_LOCALIZED,
  HEADER_NATIONAL,
  HERO,
  MARKETING_BANNERS,
  SEO,
  SIDEKICKS
} from '@/graphql/queries';

import {
  convertOccasionToGqlOccasion,
  convertStoreToGqlStoreFormat,
  isNational
} from '@/graphql/gqlDataManipulation';
import {
  isGqlClientSideDecorationDisabled,
  isGqlSsrDisabled
} from './apolloFeatureToggles';
import {
  QueryParamsProps,
  TokenData
} from './types';
import { GqlVersion } from '@/graphql/hooks/useGqlVersion/GqlVersion';

/* This file is intended to abstract away the GQL query caching for each page
   - Pages that need GQL queries past those on every page need a pageID
   - Add the pageID to the switch below to cache specific queries for that page */

type GqlContent = (version: GqlVersion | undefined) => { query: DocumentNode };

const cacheApolloQueriesForPage = async (
  pageID: string,
  tokenData: TokenData,
  timestamp: string,
  ctx: GetServerSidePropsContext
): Promise<NormalizedCacheObject> => {
  const { publicRuntimeConfig } = getConfig();
  const apolloClient = getApollo(
    undefined,
    tokenData.gqlUrl,
    isGqlClientSideDecorationDisabled(ctx)
  );
  const gqlVersionOverride = tokenData.gqlVersion || publicRuntimeConfig.GQL_VERSION;
  const isGqlVersionV3 = gqlVersionOverride === 'v3';
  const storeID = tokenData.storeNumber;
  const occasion = convertOccasionToGqlOccasion(
    tokenData.occasion,
    isGqlVersionV3
  );
  const cmsEnvID = tokenData.cmsEnvId || publicRuntimeConfig.CMS_ENVIRONMENT_ID;
  const gqlEnvStoreID = convertStoreToGqlStoreFormat(
    storeID,
    isGqlVersionV3,
    gqlVersionOverride
  );
  const isLocalized = !isNational(storeID);

  const queryParams: QueryParamsProps = {
    envEnvironmentID: cmsEnvID,
    cmsEnvironmentID: cmsEnvID,
    gqlVersion: gqlVersionOverride,
    storeID: gqlEnvStoreID,
    occasions: [occasion]
  };

  const createQueryPromise = (gqlContent: GqlContent, variables: any) => apolloClient.query({
    query: gqlContent(gqlVersionOverride).query,
    variables
  });

  const generateHomePageQueries = () => {
    if (isGqlSsrDisabled(ctx)) return [];

    const queries = [
      { gqlContent: isLocalized ? FOOTER_LOCALIZED : FOOTER_NATIONAL, variables: queryParams },
      { gqlContent: isLocalized ? HEADER_LOCALIZED : HEADER_NATIONAL, variables: queryParams },
      { gqlContent: HERO, variables: { ...queryParams, timestamp } },
      { gqlContent: SIDEKICKS, variables: { ...queryParams, timestamp } },
      { gqlContent: BREAKING_NEWS, variables: { envEnvironmentID: queryParams.envEnvironmentID } },
      { gqlContent: MARKETING_BANNERS, variables: { envEnvironmentID: queryParams.envEnvironmentID } },
      { gqlContent: SEO, variables: { ...queryParams, pageID } }
    ];

    return queries.map(({ gqlContent, variables }) => createQueryPromise(gqlContent, variables));
  };

  const homePageQueries = generateHomePageQueries();

  await Promise.allSettled(homePageQueries);

  return apolloClient.cache.extract();
};

export default cacheApolloQueriesForPage;
