import authRedirect from './redirects/authRedirect';
import { PageRedirect, RedirectProps } from './pageRedirect.types';

const pageRedirect = (props: RedirectProps): PageRedirect | undefined => {
  const authRedirectResult = authRedirect(props);

  return authRedirectResult;
};

export default pageRedirect;
