import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useDecision } from '@optimizely/react-sdk';
import { useRouter } from 'next/router';
import clsx from 'clsx';
import { Product } from '@pizza-hut-us-development/client-core';

import { Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import HutFavoriteHeader from '@/homepage/HutFavoritesSection/components/HutFavoritesHeader';

import MenuTile from '@/shared/molecules/MenuTile';

import ProductId from '@/common/ProductId';
import Routes from '@/router/routes';
import { openLocalizationRail } from '@/localization/actions';

import useGetHutFavorites from '@/homepage/HutFavoritesSection/hooks/useGetHutFavorites';
import useOneClickAnalytics from '@/homepage/HutFavoritesSection/hooks/useOneClickAnalytics';
import useHutFavoritesSectionClasses from './styles';
import useAnalytics from '@/dataAnalytics/hooks/useAnalytics';
import { homePopularPizzaImpression } from '@/dataAnalytics/dataAnalyticsHelper';

export const HUT_FAVORITES_CONTAINER_TESTID = 'hut-favorites-one-click-container';
const LOADING_ITEM_COUNT = 4;

const HutFavoritesSection = (): JSX.Element => {
  const analytics = useAnalytics();
  const hasFiredAnalyticsEvent = useRef(false);
  const [{ enabled: oneClickPopularPizzaTracking }] = useDecision('dtg-425-one-click-popular-pizza-tracking');
  const classes = useHutFavoritesSectionClasses();
  const dispatch = useDispatch();
  const { hutFavorites, isNationalStore, isLoading } = useGetHutFavorites();
  const { onHutFavoritesAddToCartClick, onHutFavoritesCustomizeLinkClick } = useOneClickAnalytics();
  const router = useRouter();

  useEffect(() => {
    if (!oneClickPopularPizzaTracking || hasFiredAnalyticsEvent.current) return;
    hasFiredAnalyticsEvent.current = true;
    analytics.push(() => homePopularPizzaImpression());
  }, [analytics, oneClickPopularPizzaTracking]);

  const handleCustomizePopularPizza = (favorite: Product) => {
    if (isNationalStore) {
      dispatch(openLocalizationRail());
      return;
    }

    if (favorite.outOfStock) return;

    const { globalId } = new ProductId(favorite.id);
    router.push(`${Routes.MENU.PIZZA_BUILDER}?id=${globalId}`);
  };

  return (
    <div
      id="hut-favorites-one-click-container"
      data-testid={HUT_FAVORITES_CONTAINER_TESTID}
      className={classes.hutFavoritesSectionContainer}
    >
      <HutFavoriteHeader />

      <Box display="grid" gridGap="24px" className={classes.cardGrid}>
        {isLoading && !hutFavorites.length && (Array.from(Array(LOADING_ITEM_COUNT)).map((_, index) => (
          <Skeleton
            key={index}
            data-testid="popular-pizza-section-loaders"
            variant="rect"
            className={clsx(classes.box, classes.skeleton)}
          />
        )))}
        {hutFavorites.map((favorite, index) => (
          <MenuTile
            key={index}
            product={favorite}
            handleClick={() => handleCustomizePopularPizza(favorite)}
            isNationalStore={isNationalStore}
            badgeOptions={{ popular: { isHidden: true } }}
            handleAnalyticsAddToCartClick={() => onHutFavoritesAddToCartClick(index, favorite)}
            handleAnalyticsCustomizeLinkClick={() => onHutFavoritesCustomizeLinkClick(index, favorite)}
          />
        ))}
      </Box>
    </div>
  );
};

export default HutFavoritesSection;
