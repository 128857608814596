import cookie from 'next-cookies';
import { GetServerSidePropsContext } from 'next';
import { DEFAULT_EXPERIENCE } from '@/salesExperience/constants';
import logger from '@/common/logger';

export default function getSalesExperience(ctx: GetServerSidePropsContext) : string {
  const experience = cookie(ctx)['Sales-Experience'];
  logger.withoutTelemetry.info(`Sales-Experience cookie: ${experience}`);
  return experience || DEFAULT_EXPERIENCE;
}
