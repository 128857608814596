import { computeNutrition, Modifier, ModifierSubtype } from '@pizza-hut-us-development/client-core';

// the subtype constants are defined in clientcore
export const CRUST_SUBTYPE = 'crusts';
export const SIZE_SUBTYPE = 'sizes';
export const CHEESE_CUBTYPE = 'cheeses';
export const SAUCE_SUBTYPE = 'sauces';

export const getSubtype = (modifier: Modifier): ModifierSubtype | undefined => {
  let { subtype } = modifier;
  if (subtype) return subtype;
  if (modifier.name === 'Sauces') {
    subtype = SAUCE_SUBTYPE;
  } else if (modifier.name === 'Cheeses') {
    subtype = CHEESE_CUBTYPE;
  }
  return subtype;
};

interface ModifierCategories {
  crust?: Modifier;
  size?: Modifier;
  cheese?: Modifier;
  sauce?: Modifier;
  toppings: Modifier[];
}

const categorizeModifiers = (modifiers: Modifier[]): ModifierCategories => (
  modifiers.reduce((acc, modifier) => {
    switch (modifier.subtype) {
      case CRUST_SUBTYPE:
        acc.crust = modifier;
        break;
      case SIZE_SUBTYPE:
        acc.size = modifier;
        break;
      case CHEESE_CUBTYPE:
        acc.cheese = modifier;
        break;
      case SAUCE_SUBTYPE:
        acc.sauce = modifier;
        break;
      default:
        acc.toppings.push(modifier);
    }
    return acc;
  }, { toppings: [] } as ModifierCategories)
);

export function getNutritionInfo(modifiers: Modifier[]): string {
  const {
    crust, size, cheese, sauce, toppings
  } = categorizeModifiers(modifiers);
  return computeNutrition(size, crust, cheese, sauce, toppings);
}
