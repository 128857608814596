import React from 'react';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import { Product } from '@pizza-hut-us-development/client-core';
import { Card, Grid } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import IconButton from '@material-ui/core/IconButton';
import ResponsiveImageRender from '@/common/ResponsiveImageRender';
import useGetSizeCrustDetails from '@/common/ProductDetails/hooks/useGetSizeCrustDetails';
import ProductId from '@/common/ProductId';
import { openLocalizationRail } from '@/localization/actions';
import Routes from '@/router/routes';
import useAnalytics from '@/dataAnalytics/hooks/useAnalytics';
import { onHutFavoriteClick } from '@/dataAnalytics/dataAnalyticsHelper';
import { AnalyticsDataModel } from '@/rootStateTypes';
import useStyles from './styles';

export const PIZZA_MENU_TILE_LEGACY_TESTID = 'pizza-menu-tile-legacy';

interface SingleHutFavoriteLegacyProps {
  favorite: Product;
  index: number;
  isNationalStore: boolean;
}

const SingleHutFavoriteLegacy = (props: SingleHutFavoriteLegacyProps): JSX.Element => {
  const {
    favorite,
    isNationalStore,
    index
  } = props;

  const classes = useStyles();
  const router = useRouter();
  const analytics = useAnalytics();
  const dispatch = useDispatch();

  const { globalId } = new ProductId(favorite.id);
  const analyticsLabel = `product_${index + 1}`;
  const ariaLabel = `${favorite.name}. Click to order.`;
  const sizeAndCrust = useGetSizeCrustDetails(true, favorite);
  const item = (dataModel: AnalyticsDataModel) => dataModel?.page?.items[index];

  const handleSelectHutFavorite = () => {
    analytics.push(() => onHutFavoriteClick(analyticsLabel, item(analytics.analyticsDataModel)));
    if (isNationalStore) {
      dispatch(openLocalizationRail());
      return;
    }
    if (favorite.outOfStock) return;

    router.push(`${Routes.MENU.PIZZA_BUILDER}?id=${globalId}`);
  };

  return (
    <Grid data-testid={PIZZA_MENU_TILE_LEGACY_TESTID} key={index} item onClick={handleSelectHutFavorite}>
      <Card className={classes.hutFavorite}>
        <ResponsiveImageRender
          mobile={favorite.imageURL || ''}
          desktop={favorite.imageURL || ''}
          altText=""
          title=""
          pictureClassName={classes.hutPictureContainer}
          className={classes.hutFavoriteImage}
          testId={`homepage-pop_pizza_${index}-image`}
        />
        <div aria-hidden className={classes.hutFavoriteTextContent}>
          <div className={classes.hutFavoriteTitle}>{favorite.name}</div>
          <div className={classes.hutFavoriteSubTitle}>{sizeAndCrust}</div>
        </div>
        <IconButton tabIndex={-1} className={classes.iconButton} disableRipple aria-label={ariaLabel}>
          <ArrowForwardIosIcon fontSize="small" className={classes.caratIcon} />
        </IconButton>
      </Card>
    </Grid>
  );
};

export default SingleHutFavoriteLegacy;
