import { makeStyles, createStyles } from '@material-ui/core/styles';
import fontStyles from '../../../common/fontStyles';
import boxShadows from '../../../common/boxShadows';
import { mobileStartBreakpoint } from '../../../materialUi/theme';
import { getPaddingTopForAspectRatio } from '../../../common/aspectRatio';
import colors from '../../../common/colors';

export default makeStyles((theme) => createStyles({
  anchorLink: {
    ...fontStyles.commonComponentSelectedState,
    display: 'block',
    textDecoration: 'none',
    height: '100%',
    cursor: 'pointer'
  },
  card: {
    height: '100%',
    maxWidth: '100%',
    boxShadow: boxShadows.cardShadow,
    backgroundColor: colors.white
  },
  grid: {
    alignSelf: 'normal'
  },
  cardMediaWrapper: {
    height: '100%',
    width: '100%',
    position: 'absolute',
    display: 'block',
    top: 0,
    left: 0
  },
  media: {
    height: '100%',
    width: '100%',
    objectFit: 'cover'
  },
  aspectRatioBoxSm: {
    position: 'relative',
    height: '100%',
    paddingTop: getPaddingTopForAspectRatio(172, 210),
    [theme.breakpoints.up(mobileStartBreakpoint)]: {
      paddingTop: getPaddingTopForAspectRatio(352, 282)
    }
  },
  aspectRatioBoxLg: {
    position: 'relative',
    height: '100%',
    paddingTop: getPaddingTopForAspectRatio(172, 210),
    [theme.breakpoints.up(mobileStartBreakpoint)]: {
      paddingTop: getPaddingTopForAspectRatio(541, 282)
    }
  },
  imageSkeleton: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    height: '100%',
    content: '""',
    backgroundColor: 'rgba(0, 0, 0, 0.11)',
    animation: '$pulse 1.6s ease-in-out 0.5s infinite'
  },
  '@keyframes pulse': {
    '0%': {
      opacity: 1
    },
    '50%': {
      opacity: 0.4
    },
    '100%': {
      opacity: 1
    }
  }
}));
