import { useDecision } from '@optimizely/react-sdk';
import { useSelector } from 'react-redux';
import { localizationSelectors } from '@/localization/localizationSelectors';
import { MenuRecipe } from '@/menu/pizza/pizzaMenuTypes';
import getDiscountCodeFromId from './common/getDiscountCodeFromId';

type DealTileSuppressionVariables = {
  tile_suppression_stores: { stores: string[] };
  tile_suppress_deal_id: string;
};

const useTileSuppressionExperiment = (deals: MenuRecipe[] = [], loading = false): MenuRecipe[] => {
  const storeDetails = useSelector(localizationSelectors.storeDetails);
  const [decision] = useDecision('exp-web527-deal_tile_suppression');

  // Return the original deals list if the experiment is not enabled or the loading flag is set.
  if (loading || !decision.enabled) {
    return deals;
  }

  const decisionVariables = decision.variables as DealTileSuppressionVariables;
  const experimentStores = decisionVariables.tile_suppression_stores?.stores || [];
  const suppressedDealId = decisionVariables.tile_suppress_deal_id || '';

  // Return the original deals list if the store is not included in the experiment.
  if (!experimentStores.includes(storeDetails.storeNumber)) {
    return deals;
  }

  // Filter out the suppressed deal from the deals list
  return deals.filter((deal) => getDiscountCodeFromId(deal.id) !== suppressedDealId);
};

export default useTileSuppressionExperiment;
