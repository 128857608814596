import { makeStyles, createStyles } from '@material-ui/core/styles';
import fontStyles from '../../common/fontStyles';

export default makeStyles(() => createStyles({
  container: {
    height: 0,
    margin: 0,
    '& a': {
      ...fontStyles.bodyLarge,
      fontWeight: 600,
      position: 'fixed',
      top: '-3em',
      zIndex: '1050',
      '&:focus': {
        top: '5px',
        left: '5px',
        padding: '1em',
        backgroundColor: 'white'
      }
    }
  }

}));
