import { OptimizelyDecision } from '@optimizely/react-sdk';
import { MenuRecipe } from '@/menu/pizza/pizzaMenuTypes';
import { getGlobalId } from '@/cart/helpers/getGlobalId';

type DealTileReorderVariables = {
  tile_order_variation: number;
  tile_order_variations: { deals: [string[], string[], string[]] };
  tile_order_position: { position : number[] };
};

const useTileReorderExperiment = (deals: MenuRecipe[] = [], decision:OptimizelyDecision): MenuRecipe[] => {
  // Return the original deals list if the experiment is not enabled.
  if (!decision?.enabled) {
    return deals;
  }

  const decisionVariables = decision.variables as DealTileReorderVariables;
  const dealVariationToUse = decisionVariables.tile_order_variation || 0;
  const dealVariations = decisionVariables.tile_order_variations?.deals || [];
  const positionListToSwap = decisionVariables.tile_order_position.position || [];
  const variation = dealVariations[dealVariationToUse];
  const sortedDeals = deals;

  // Reorder the deals list based on the experiment variation.
  if (sortedDeals?.length) {
    variation?.forEach((name, index) => {
      const variationIndex = deals.findIndex((deal) => getGlobalId(deal.id) === name);
      const swapIndex = positionListToSwap[index];
      if (variationIndex > -1 && swapIndex < deals.length) {
        // swap priority order
        [sortedDeals[variationIndex].priority, sortedDeals[swapIndex].priority] = [sortedDeals[swapIndex].priority, sortedDeals[variationIndex].priority];
        // swap the objects to avoid reassigning priority
        [sortedDeals[variationIndex], sortedDeals[swapIndex]] = [sortedDeals[swapIndex], sortedDeals[variationIndex]];
      }
    });
  }

  return sortedDeals;
};

export default useTileReorderExperiment;
