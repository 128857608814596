import { Product } from '@pizza-hut-us-development/client-core';
import { useDecision } from '@optimizely/react-sdk';
import { ItemInputAnalytics } from '@/dataAnalytics/analyticsTypes';
import { onHutFavoriteOneClick } from '@/dataAnalytics/dataAnalyticsHelper';
import useAnalytics from '@/dataAnalytics/hooks/useAnalytics';
import telemetry from '@/telemetry';
import { MenuRecipe } from '@/menu/pizza/pizzaMenuTypes';

const useOneClickAnalytics = () => {
  const analytics = useAnalytics();

  const [{ enabled: oneClickCustomTrackingEnabled }] = useDecision('dtg-958-one-click-pizza-menu-page-tracking');
  const [{ enabled: oneClickAddToCartTrackingEnabled }] = useDecision('dtg-955-one-click-add-to-cart-pizza-menu-page-tracking');

  const onHutFavoritesAddToCartClick = (index: number, favorite: Product) => {
    const item: ItemInputAnalytics = {
      itemIndex: index,
      itemList: 'Popular Pizza',
      itemPrice: favorite.price || 0,
      itemName: favorite.name,
      itemCategory: 'Popular Pizza',
      itemID: favorite.id,
      itemPosition: `${index}`,
      outOfStock: false
    };
    telemetry.addCustomEvent(`homepage-hut-favorite-${index + 1}-add-to-cart-click`);
    analytics.push(() => onHutFavoriteOneClick(`product_${index}`, item, true));
  };

  const onHutFavoritesCustomizeLinkClick = (index: number, favorite: Product) => {
    const item: ItemInputAnalytics = {
      itemIndex: index,
      itemList: 'Popular Pizza',
      itemPrice: favorite.price || 0,
      itemName: favorite.name,
      itemCategory: 'Popular Pizza',
      itemID: favorite.id,
      itemPosition: `${index}`,
      outOfStock: false
    };

    telemetry.addCustomEvent(`homepage-hut-favorite-${index + 1}-customize-link-click`);
    analytics.push(() => onHutFavoriteOneClick(`product_${index}`, item, false));
  };

  const onPizzaMenuCustomizeLinkClick = (product: Product, index: number) => {
    if (!oneClickCustomTrackingEnabled) return;
    analytics.push(() => ({
      beacon_id: '99.99.99',
      event: 'button_click',
      event_action: 'Customize',
      event_category: 'Menu > Pizza',
      event_label: 'Menu > Pizza > Customize',
      product_name_hit: product.name,
      product_action: 'click',
      products: [{
        product_index: index,
        product_id: product.id,
        product_name: product.name,
        product_category: 'Pizza',
        product_price: product.price,
        product_quantity: product.selectedOptions,
        product_action_list: 'Pizza',
        product_position: `${index + 1}`,
        product_size: 'Large'
      }]
    }));
  };

  const onPizzaMenuAddToCartClick = (product: Product, index: number, quantity: number) => {
    if (!oneClickAddToCartTrackingEnabled) return;
    analytics.push(() => ({
      beacon_id: '99.99.99',
      event: 'product_click',
      event_action: 'Add to Order',
      event_category: 'Menu > Pizza',
      event_label: 'Menu > Pizza > Add to Order',
      product_name_hit: product.name,
      product_action: 'click',
      products: [{
        product_index: index,
        product_id: product.id,
        product_name: product.name,
        product_category: 'Pizza',
        product_price: product.price,
        product_quantity: quantity,
        product_action_list: 'Pizza',
        product_position: `${index + 1}`,
        product_size: product.selectedOptions.find((option) => option.type === 'SIZE')?.name
      }]
    }));
  };

  const onPizzaMenuAddToCartSuccess = (product: Product, index: number, quantity: number) => {
    if (!oneClickAddToCartTrackingEnabled) return;
    analytics.push(() => ({
      beacon_id: '99.99.99',
      event: 'cart_add',
      event_action: 'Add to Order',
      event_category: 'Menu > Pizza',
      event_label: 'Menu > Pizza > Add to Order',
      product_name_hit: product.name,
      product_action: 'add',
      products: [{
        product_id: product.id,
        product_name: product.name,
        product_quantity: quantity,
        product_price: product.price,
        product_position: `${index + 1}`,
        product_sauce: product.selectedOptions.find((option) => option.subtype === 'sauces')?.name,
        product_cheese: product.selectedOptions.find((option) => option.subtype === 'cheeses')?.name,
        product_crust: product.selectedOptions.find((option) => option.subtype === 'crusts')?.name,
        product_size: product.selectedOptions.find((option) => option.type === 'SIZE')?.name,
        product_veggies: product.selectedOptions.filter((option) => option.subtype === 'veggies').map((meat) => meat.name).join(', '),
        product_meats: product.selectedOptions.filter((option) => option.subtype === 'meats').map((meat) => meat.name).join(', '),
        product_index: index,
        product_category: 'Pizza',
        product_added_location: 'Menu > Pizza'
      }]
    }));
  };

  const onGetStartedLinkClick = (product: Product | MenuRecipe, index: number) => {
    analytics.push(() => ({
      beacon_id: '99.99.99',
      event: 'button_click',
      event_action: 'Pizza List Click',
      event_category: 'Menu > Pizza',
      event_label: 'Menu > Pizza > Pizza List Click',
      product_name_hit: product.name,
      product_action: 'click',
      products: [{
        product_index: index,
        product_id: product.id,
        product_name: product.name,
        product_category: 'Pizza',
        product_price: product.price,
        product_quantity: 1,
        product_action_list: 'Pizza',
        product_position: `${index + 1}`,
        product_size: 'Large'
      }]
    }));
  };

  return {
    onHutFavoritesAddToCartClick,
    onHutFavoritesCustomizeLinkClick,
    onPizzaMenuCustomizeLinkClick,
    onPizzaMenuAddToCartClick,
    onPizzaMenuAddToCartSuccess,
    onGetStartedLinkClick
  };
};

export default useOneClickAnalytics;
