import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { mobileStartBreakpoint } from '@/materialUi/theme';
import boxShadows from '@/common/boxShadows';
import fontStyles from '@/common/fontStyles';
import colors from '@/common/colors';

const styles = makeStyles((theme: Theme) => createStyles({
  hutFavorite: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxShadow: boxShadows.cardShadow,
    height: '110px',
    cursor: 'pointer'
  },
  iconButton: {
    padding: '6px'
  },
  hutPictureContainer: {
    height: 'inherit'
  },
  hutFavoriteImage: {
    height: '115px',
    width: 'auto'
  },
  hutFavoriteTextContent: {
    flexGrow: 1,
    marginLeft: '10px'
  },
  hutFavoriteTitle: {
    ...fontStyles.productTitle,
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      ...fontStyles.productTitleMobile
    }
  },
  hutFavoriteSubTitle: {
    fontSize: '14px',
    color: colors.gray900
  },
  caratIcon: {
    color: colors.gray900
  },
  hutFavoriteTitleLoading: {
    width: '8rem'
  },
  hutFavoriteSubtitleLoading: {
    width: '12rem'
  }
}));

export default styles;
