import React, { useEffect } from 'react';
import { ApolloError} from '@apollo/client';
import Grid from '@material-ui/core/Grid';
import { compact } from 'remeda';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { SIDEKICKS } from '../../graphql/queries';
import SectionHeader from '../sectionHeader';
import Sidekick from './SingleSidekick';
import { logArrayEmptyError } from '@/common/logger/logGraphqlError';
import isSmallSidekickImage from '../../common/isSmallSidekickImage';
import { breakPointsValues, largeStartBreakpoint, mobileStartBreakpoint } from '@/materialUi/theme';
import useWindowSize from '../../common/useWindowSize';
import { PromoType } from '../../dataAnalytics/dataAnalytics.d';
import { onGqlError } from '@/graphql/errors/actions';
import { QueryNames } from '@/graphql/errors/constants';
import { useVersionQuery } from '../../graphql/hooks/useVersionQuery';
import useEnvQueryVariables from '../../graphql/hooks/variables/useEnvQueryVariables';
import { updatePromoData } from '@/ReduxApplication/actions';
import { SidekicksData } from '../HomepageTypes';
import { useWingsSidekickImgExperiment } from '@/experiments';

const useStyles = makeStyles((theme) => createStyles({
  root: {
    marginTop: '41px',
    marginBottom: '48px',
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      marginTop: '16px',
      marginBottom: '32px'
    }
  }
}));

export interface HomepageSidekick {
  index: number;
  title?: string;
  subTitle: string;
  displayPrice: boolean;
  item: { price: number };
  badgeText: string;
  link: LinkContent;
  imageSmall: Image;
  imageLarge: Image;
}

const HomepageSidekickSection = (): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const windowSize = useWindowSize();
  const isMobile = windowSize.width ? windowSize.width < breakPointsValues[largeStartBreakpoint] : false;
  const envQueryVariables = useEnvQueryVariables();

  const {
    data,
    loading,
    error,
    storeID
  } = useVersionQuery<SidekicksData>({
    queryFn: SIDEKICKS,
    storeSpecific: true,
    itemId: '',
    options: envQueryVariables,
    nationalOverrides: true
  });

  const loadingDetails = {
    heading: 'Deals',
    ctaLink: {
      external: false,
      link: '',
      linkDisplayText: ''
    },
    sidekicks: [{}, {}, {}, {}, {}] as HomepageSidekick[]
  };

  const finishedLoadingButNoData = !loading && !data?.dealsheading;
  const sidekicksData = data?.sidekicks;

  const { heading, ctaLink, sidekicks } = loading || error ? loadingDetails : getValidSidekicks(data as SidekicksData);
  const sideKicksWithExperiment = useWingsSidekickImgExperiment(sidekicks, loading);

  useEffect(() => {
    if (sidekicksData) {
      const sidekicks = sidekicksData || [];
      dispatch(updatePromoData(PromoType.SIDEKICKS_PROMO, sidekicks, isMobile));

      if (sidekicks.length === 0) {
        logArrayEmptyError('Homepage sidekick', storeID);
      }
    }
  }, [dispatch, isMobile, sidekicksData, storeID]);

  useEffect(() => {
    if (error || finishedLoadingButNoData) {
      dispatch(onGqlError(QueryNames.SIDEKICK, error as ApolloError, storeID));
    }
  }, [dispatch, error, finishedLoadingButNoData, loading, storeID]);

  if (error || finishedLoadingButNoData) {
    return <></>;
  }

  return (
    <div className={classes.root}>
      <SectionHeader
        heading={heading}
        linkTestId="homepage-sidekick_header_CTA-text"
        dataAnalyticsCategory="deals"
        dataAnalyticsAction="All deals"
        {...ctaLink}
      />
      <Grid container spacing={3} data-testid="homepage-sidekick-grid">
        {
          sideKicksWithExperiment.map((sidekick: HomepageSidekick, index) => (
            <Sidekick
              key={index}
              index={index}
              title={sidekick.title}
              subTitle={getSideKickPriceOrSubTitle(sidekick)}
              badgeText={sidekick.badgeText}
              link={sidekick.link}
              imageSmall={sidekick.imageSmall}
              imageLarge={sidekick.imageLarge}
              isSmallImage={isSmallSidekickImage(index, sideKicksWithExperiment?.length)}
              loading={loading}
              storeID={storeID}
            />
          ))
}
      </Grid>
    </div>
  );
};

interface ValidSidekicksData {
  heading: string;
  ctaLink: LinkContent;
  sidekicks: HomepageSidekick[];
}

function formatPrice(price: number) {
  return `$${(price / 100).toFixed(2)}`;
}

function getSideKickPriceOrSubTitle(sidekick: HomepageSidekick) {
  return sidekick.displayPrice && sidekick.item?.price
    ? formatPrice(sidekick.item.price)
    : sidekick.subTitle;
}

function getValidSidekicks(data: SidekicksData): ValidSidekicksData {
  const { heading, ctaLink } = data?.dealsheading;
  const {
    sidekicks: [first, second, third, fourth, fifth]
  } = data;

  return {
    heading,
    ctaLink,
    sidekicks: compact([first, second, third, fourth, fifth]) as unknown as HomepageSidekick[]
  };
}

export default HomepageSidekickSection;
