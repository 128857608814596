import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import useEnvQueryVariables from '../../graphql/hooks/variables/useEnvQueryVariables';
import { usePageLoadedAndInView } from '../../dataAnalytics/hooks/useScrollTrackingForAnalytics';
import { useVersionQuery } from '../../graphql/hooks/useVersionQuery';
import { HERO } from '../../graphql/queries';
import { logMissingImageError, logMissingLinkError } from '../../common/logger/logGraphqlError';
import { onGqlError } from '../../graphql/errors/actions';
import { QueryNames } from '../../graphql/errors/constants';

interface HeroQueryResult {
  hero: Hero;
}

interface HomepageHeroProps {
  hero: Hero;
  loading: boolean;
  hasNoHero: boolean;
  pushInViewAnalytics: boolean;
  ref: (node?: Element | null) => void;
}

const validateHero = (data: HeroQueryResult): boolean => Boolean(
  data?.hero
    && data?.hero?.image?.desktop
    && data?.hero?.image?.mobile
);

export default function useHomepageHero(): HomepageHeroProps {
  const dispatch = useDispatch();
  const envQueryVariables = useEnvQueryVariables();
  const pageLoadedAndInView = usePageLoadedAndInView();

  const {
    data, loading, error, storeID
  } = useVersionQuery<{ hero: HomepageHero }>({
    queryFn: HERO,
    storeSpecific: true,
    itemId: '',
    options: envQueryVariables,
    nationalOverrides: true
  });

  const heroData: Hero = data?.hero;
  const finishedLoadingButNoData: boolean = !loading && !heroData;
  const finishedLoadingButInvalidData: boolean = !loading && !validateHero(data);

  useEffect(() => {
    if (heroData) {
      logMissingImageError([heroData], 'Homepage hero', storeID);
      logMissingLinkError([heroData], 'Homepage hero', storeID);
    }
  }, [heroData, storeID]);

  useEffect(() => {
    if (error || finishedLoadingButNoData) {
      dispatch(onGqlError(QueryNames.HERO, error, storeID));
    }
  }, [dispatch, error, finishedLoadingButNoData, storeID]);

  return {
    loading,
    hero: heroData,
    ref: pageLoadedAndInView.ref,
    pushInViewAnalytics: Boolean(data && pageLoadedAndInView.isPageInView),
    hasNoHero: Boolean(error || finishedLoadingButNoData || finishedLoadingButInvalidData)
  };
}
