import cookie from 'next-cookies';
import { YUM_GUEST_REFRESH_COOKIE_NAME, YUM_GUEST_EXPIRY_COOKIE_NAME } from '@/configuration/constants';
import { YumRefreshTokenData } from '@/services/authService';

export function getYumAuthRefreshData(ctx: any): YumRefreshTokenData {
  const payload: YumRefreshTokenData = {};
  const refreshToken = cookie(ctx)[YUM_GUEST_REFRESH_COOKIE_NAME];
  const expiresAt = cookie(ctx)[YUM_GUEST_EXPIRY_COOKIE_NAME];

  if (refreshToken) {
    payload.refreshToken = refreshToken;
  }

  if (expiresAt) {
    payload.expiresAt = expiresAt;
  }

  return payload;
}
