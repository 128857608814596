import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import {
  mobileStartBreakpoint
} from '@/materialUi/theme';
import fontStyles from '@/common/fontStyles';

const useHutFavoriteHeaderClasses = makeStyles((theme: Theme) => createStyles({
  header: {
    ...fontStyles.h1,
    margin: 0,
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      ...fontStyles.h1Mobile,
      lineHeight: 'normal'
    }
  },
  link: {
    ...fontStyles.redTextLink,
    letterSpacing: '1.25px',
    marginLeft: '20px',
    textDecoration: 'none',
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      margin: '0 0 0 15px',
      display: 'flex',
      alignItems: 'center'
    }
  },
  caret: {
    ...fontStyles.caretIcon
  }
}));

export default useHutFavoriteHeaderClasses;
