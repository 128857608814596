import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import {
  mobileStartBreakpoint,
  smallMobileStartBreakpoint
} from '../../materialUi/theme';
import fontStyles from '../../common/fontStyles';

export default makeStyles((theme: Theme) => createStyles({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    height: '36px',
    lineHeight: 1.33,
    marginBottom: '24px',
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      lineHeight: 'normal',
      marginTop: '16px'
    },
    [theme.breakpoints.down(smallMobileStartBreakpoint)]: {
      justifyContent: 'space-between'
    }
  },
  headerText: {
    ...fontStyles.h1,
    lineHeight: '28px',
    alignSelf: 'flex-end',
    margin: 0,
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      ...fontStyles.h1Mobile,
      height: '36px',
      lineHeight: 'normal'
    }
  },
  linkText: {
    ...fontStyles.redTextLink,
    margin: '20px 0px 0px 18px',
    textAlign: 'right',
    letterSpacing: '1.25px',
    textDecoration: 'none',

    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      margin: '0 0 0 15px',
      display: 'flex',
      alignItems: 'center'
    }
  },
  headerIcon: {
    ...fontStyles.caretIcon
  }
}));
