import getConfig from 'next/config';

let datafile: Record<string, unknown> | null = null;
const OPTIMIZELY_SDK_KEY = getConfig()?.publicRuntimeConfig.OPTIMIZELY_SDK_KEY;

const fetchDatafile = async (): Promise<Record<string, unknown>> => {
  try {
    const res = await fetch(
      `https://cdn.optimizely.com/datafiles/${OPTIMIZELY_SDK_KEY}.json`,
      { method: 'get' }
    );

    if (res.ok) {
      datafile = await res.json();
    }

    if (!datafile) {
      return {};
    }

    return datafile;
  } catch (error) {
    return {};
  }
};

export const getDatafile = async (): Promise<Record<string, unknown>> => {
  if (datafile) {
    return Promise.resolve(datafile);
  }

  return fetchDatafile();
};
