import cookie from 'next-cookies';
import { GetServerSidePropsContext } from 'next';
import { ADVANCED_CART_DIAGNOSTICS_COOKIE_NAME } from '@/configuration/constants';

const cookieName = ADVANCED_CART_DIAGNOSTICS_COOKIE_NAME;

export default function getEnableCartDiagnostics(
  ctx: GetServerSidePropsContext
): string {
  return cookie(ctx)[cookieName] ?? 'false';
}
