import { useDecision } from '@optimizely/react-sdk';
import { useSelector } from 'react-redux';
import { localizationSelectors } from '@/localization/localizationSelectors';
import { HomepageSidekick } from '@/homepage/sidekicks/HomepageSideKickSection';

type WingsSidekickImgVariables = {
  '799_wings_image_stores': { stores: string[] };
  '699_wings_image_stores': { stores: string[] };
  wingsk_imageLarge_desktop: string;
};

const useWingsSidekickImgExperiment = (sidekicks: HomepageSidekick[] = [], loading = false): HomepageSidekick[] => {
  const storeDetails = useSelector(localizationSelectors.storeDetails);
  const [decision] = useDecision('exp-web979-wings_sidekick_image_pricing');

  // Return the original sidekicks list if the experiment is not enabled or loading.
  if (!decision.enabled || loading) {
    return sidekicks;
  }

  const decisionVariables = decision.variables as WingsSidekickImgVariables;
  const experimentStores799 = decisionVariables['799_wings_image_stores']?.stores || [];
  const experimentStores699 = decisionVariables['699_wings_image_stores']?.stores || [];

  // $7.99 experiment
  if (experimentStores799.includes(storeDetails?.storeNumber)) {
    const targetSidekick = sidekicks.find((sidekick) => sidekick.imageLarge.desktop === decisionVariables.wingsk_imageLarge_desktop);

    // Return the original sidekick list if we don't find our target sidekick to update.
    if (!targetSidekick) {
      return sidekicks;
    }

    const newSidekicks = sidekicks.map((sidekick) => {
      if (sidekick === targetSidekick) {
        return {
          ...targetSidekick,
          imageLarge: {
            ...targetSidekick.imageLarge,
            desktop: '/images/experiments/PH_TL_2023_WingTest_$7.99_541x282.jpg',
            mobile: '/images/experiments/PH_TL_2023_WingTest_$7.99_541x282.jpg'
          },
          imageSmall: {
            ...targetSidekick.imageSmall,
            desktop: '/images/experiments/PH_TL_2023_WingTest_$7.99_352x282.jpg',
            mobile: '/images/experiments/PH_TL_2023_WingTest_$7.99_172x210.jpg'
          }
        };
      }
      return sidekick;
    });

    return newSidekicks;
  }

  // $6.99 experiment
  if (experimentStores699.includes(storeDetails?.storeNumber)) {
    const targetSidekick = sidekicks.find((sidekick) => sidekick.imageLarge.desktop === decisionVariables.wingsk_imageLarge_desktop);

    // Return the original sidekick list if we don't find our target sidekick to update.
    if (!targetSidekick) {
      return sidekicks;
    }

    const newSidekicks = sidekicks.map((sidekick) => {
      if (sidekick === targetSidekick) {
        return {
          ...targetSidekick,
          imageLarge: {
            ...targetSidekick.imageLarge,
            desktop: '/images/experiments/PH_TL_2023_WingTest_$6.99_541x282.jpg',
            mobile: '/images/experiments/PH_TL_2023_WingTest_$6.99_541x282.jpg'
          },
          imageSmall: {
            ...targetSidekick.imageSmall,
            desktop: '/images/experiments/PH_TL_2023_WingTest_$6.99_352x282.jpg',
            mobile: '/images/experiments/PH_TL_2023_WingTest_$6.99_172x210.jpg'
          }
        };
      }
      return sidekick;
    });

    return newSidekicks;
  }

  // Return the original sidekick list if the store is not included in either experiment.
  return sidekicks;
};

export default useWingsSidekickImgExperiment;
