import React from 'react';
import { Grid } from '@material-ui/core';
import useFallback from '../../../../common/useFallback';
import useImageDimensionsOnLoad from '../../../../common/useImageDimensionsOnLoad';
import { breakPointsValues } from '../../../../materialUi/theme';

interface SidekickMediaProps {
  index: number;
  desktopImage: string;
  mobileImage: string;
  title: string;
  alt: string;
  classes: { [key: string]: string };
  fallbackImage: string;
  loading: boolean;
  isDesktopSmallImage?: boolean;
}

const SingleSidekickMedia = ({
  index, alt, desktopImage, mobileImage, title, classes, fallbackImage, loading, isDesktopSmallImage
}: SidekickMediaProps) => {
  const { ref, onError, sourceRef } = useFallback(fallbackImage, { desktopImage, mobileImage }, loading);
  const {
    onLoad, imageLoaded
  } = useImageDimensionsOnLoad(ref);

  const loadingPropExists = typeof loading !== 'undefined';
  const stillWaiting = !imageLoaded || loading;
  const shouldShowSkeleton = loadingPropExists && stillWaiting;

  const shouldUseDesktopSmallImage = isDesktopSmallImage && isDesktopSmallImage === true;
  const gridClassName = shouldUseDesktopSmallImage
    ? classes.aspectRatioBoxSm
    : classes.aspectRatioBoxLg;

  return (
    <>
      <Grid aria-hidden item className={gridClassName}>
        {shouldShowSkeleton && (
          <div data-testid="sidekick-skeleton-media" className={classes.imageSkeleton}>&nbsp;</div>
        )}
        <picture className={classes.cardMediaWrapper}>
          <source
            media={`(min-width:${breakPointsValues.md}px)`}
            srcSet={desktopImage}
            ref={sourceRef}
            data-testid={`sidekick-picture-source_${index}`}
          />
          <img
            alt={alt}
            title={title}
            className={classes.media}
            onLoad={onLoad}
            src={mobileImage}
            ref={ref}
            onError={onError}
            loading="lazy"
          />
        </picture>
      </Grid>
    </>
  );
};

export default SingleSidekickMedia;
