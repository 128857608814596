import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { mobileStartBreakpoint, largeStartBreakpoint, extraLargeStartBreakpoint } from '@/materialUi/theme';

const useHutFavoritesSectionClasses = makeStyles((theme: Theme) => createStyles({
  hutFavoritesSectionContainer: {
    padding: '48px 0',
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      padding: '45px 0 32px'
    }
  },
  box: {
    borderRadius: 8,
    marginBottom: '2.6rem',
    [theme.breakpoints.down(largeStartBreakpoint)]: {
      marginBottom: '1.6rem'
    }
  },
  cardGrid: {
    gridTemplateColumns: '1fr',
    [theme.breakpoints.up(mobileStartBreakpoint)]: {
      gridTemplateColumns: '1fr 1fr'
    },
    [theme.breakpoints.up(extraLargeStartBreakpoint)]: {
      gridTemplateColumns: '1fr 1fr 1fr 1fr'
    }
  },
  skeleton: {
    paddingTop: '50px',
    margin: '0 auto',
    maxWidth: '1152px',
    height: '270px',
    alignContent: 'flex-start',
    flexDirection: 'column',
    width: '100%',
    [theme.breakpoints.down(largeStartBreakpoint)]: {
      paddingTop: '10px',
      width: '90%'
    }
  }
}));

export default useHutFavoritesSectionClasses;
