import React, { useEffect } from 'react';
import {
  AppBar,
  createStyles, Grid, makeStyles, Theme, Typography
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import useHeaderStyles from '../header/styles';

import ResponsiveImageRender from '@/common/ResponsiveImageRender';
import {
  extraSmallMobileStartBreakpoint, largeStartBreakpoint, mobileStartBreakpoint, smallMobileStartBreakpoint
} from '@/materialUi/theme';
import CenteredContainer from '@/common/CenteredContainer';
import fontStyles from '@/common/fontStyles';
import { closeModal } from '@/localization/actions';
import { resetAllGqlErrors } from '@/graphql/errors/actions';
import AnchorLink from '@/common/AnchorLink';
import Logo from '@/header/Logo';
import colors from '@/common/colors';
import Routes from '@/router/routes';
import telemetry from '@/telemetry';

const heartPizzaImage = {
  mobile: '/images/error-page-mobile.jpg',
  desktop: '/images/error-page-desktop.jpg'
};

const logoImage = {
  desktop: '/images/desktop-logo.png',
  mobile: '/images/mobile-logo.png',
  altText: 'logo',
  title: 'logo'
};

const useStyles = makeStyles((theme: Theme) => createStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '90px 0'
  },
  header: {
    boxShadow: 'none',
    borderBottom: `solid 1px ${colors.gray400}`
  },
  title: {
    ...fontStyles.h1,
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      ...fontStyles.h1Mobile
    }
  },
  description: {
    ...fontStyles.bodyLarge,
    textAlign: 'center',
    width: '45%',

    [theme.breakpoints.down(largeStartBreakpoint)]: {
      width: '60%'
    },

    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      width: '60%'
    },

    [theme.breakpoints.between(extraSmallMobileStartBreakpoint, smallMobileStartBreakpoint)]: {
      width: '100%'
    }
  }
}));

export const ErrorPage = () => {
  const classes = useStyles();
  const headerClasses = useHeaderStyles();
  const dispatch = useDispatch();
  const router = useRouter();
  const hadPrevPageError = !!router.query.error;

  useEffect(() => {
    telemetry.addCustomEvent('web2-error-page');
  }, []);

  useEffect(() => {
    if (hadPrevPageError) {
      dispatch(closeModal());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => () => {
    dispatch(resetAllGqlErrors());
  }, [dispatch]);

  const MenuSection = () => (
    <Grid item className={headerClasses.menuItems} role="navigation">
      <Grid className={headerClasses.menuItem}>
        <AnchorLink
          testId="error-homepage-nav-deals"
          link={Routes.DEALS}
          linkTitle="Deals"
          linkDisplayText="Deals"
          linkType="relativeLink"
          external={false}
          className={headerClasses.mainNavLink}
          dataAnalyticsCategory="global_header"
          dataAnalyticsAction="Deals"
        />
      </Grid>
      <Grid className={headerClasses.menuItem}>
        <AnchorLink
          testId="error-homepage-nav-menu"
          link={Routes.MENU.PIZZA}
          linkTitle="Menu"
          linkDisplayText="Menu"
          linkType="relativeLink"
          external={false}
          className={headerClasses.mainNavLink}
          dataAnalyticsCategory="global_header"
          dataAnalyticsAction="Menu"
        />
      </Grid>
    </Grid>
  );

  return (
    <Grid>
      <AppBar
        color="inherit"
        classes={{ root: classes.header }}
        data-testid="header-container"
        role="banner"
      >
        <Grid container className={headerClasses.containerGrid}>
          <Logo logoImage={logoImage} />
          <MenuSection />
        </Grid>
      </AppBar>
      <CenteredContainer>
        <Grid className={classes.container} data-testid="error-homepage">
          <ResponsiveImageRender
            altText="heart shaped pizza"
            title=""
            testId=""
            className=""
            mobile={heartPizzaImage.mobile}
            desktop={heartPizzaImage.desktop}
          />
          <h1 className={classes.title}>We’re sorry</h1>
          <Typography className={classes.description}>
            We ran into an error... guess not all problems can be solved with pizza. Please refresh the page and try again.
          </Typography>
        </Grid>
      </CenteredContainer>
    </Grid>
  );
};
