import { useEffect, useRef } from 'react';
import { useDecision } from '@optimizely/react-sdk';
import useAnalytics from '@/dataAnalytics/hooks/useAnalytics';

export const AATestRuleKey = 'dtg-994_aa_test_rule';
export const AATestVariationA = 'dtg-994-variation-a';
export const AATestVariationB = 'dtg-994-variation-b';

const BASE_AA_TEST_EVENT = {
  event: 'FFE-AATest',
  event_category: 'Feature Flag Experiment'
};

export function useHomepageAnalytics(): void {
  const analytics = useAnalytics();
  const [{
    enabled: aaTestEnabled,
    ruleKey: aaTestRuleKey,
    variationKey: aaTestVariationKey
  }] = useDecision('dtg-994-aa-test');
  const hasFiredEvent = useRef(false);

  useEffect(() => {
    if (!aaTestEnabled || aaTestRuleKey !== AATestRuleKey || hasFiredEvent.current) {
      return;
    }
    switch (aaTestVariationKey) {
      case AATestVariationA:
        hasFiredEvent.current = true;
        analytics.push(() => ({
          ...BASE_AA_TEST_EVENT,
          beacon_id: '99.04.02',
          event_action: 'Untreated_DTG_AAExperiment'
        }));
        break;
      case AATestVariationB:
        hasFiredEvent.current = true;
        analytics.push(() => ({
          ...BASE_AA_TEST_EVENT,
          beacon_id: '99.03.02',
          event_action: 'Treated_DTG_AAExperiment'
        }));
        break;
      default:
    }
  }, [analytics, aaTestEnabled, aaTestRuleKey, aaTestVariationKey]);
}
