import { AsyncDispatch } from '@/localization/localizeActions';
import dealService from '../services/dealService';
import { createDealLink, updateUrl } from '@/common/url';
import { openLocalizationRail, openModal } from '@/localization/actions';
import router from '../router';
import {
  SWITCH_TO_DELIVERY_BODY,
  SWITCH_TO_DELIVERY_CTA,
  SWITCH_TO_CARRYOUT_BODY,
  SWITCH_TO_CARRYOUT_CTA,
  SWITCH_TO_CARRYOUT_TITLE,
  SWITCH_TO_DELIVERY_TITLE,
  SWITCH_STORE_BODY,
  SWITCH_STORE_CTA,
  SWITCH_STORE_TITLE,
  DEAL_NOT_AVAILABLE_CTA,
  DEAL_NOT_AVAILABLE_BODY,
  DEAL_NOT_AVAILABLE_TITLE,
  DEAL_UNEXPECTED_ERROR_TITLE,
  DEAL_UNEXPECTED_ERROR_BODY,
  DEAL_UNEXPECTED_ERROR_CTA
} from './constants';
import { Occasion } from '@/localization/constants';
import { Paths } from '@/router/nextJsRouter';
import { WEB2 } from '@/salesExperience/constants';
import { Experience } from '@/salesExperience/types';
import NextRouter from 'next/router';

export const navigateToDealLink = (
  dealLink: string,
  isRelativeLink: boolean,
  experience: Experience
) => {
  const transformedDealLink = createDealLink(dealLink, isRelativeLink);
  if (experience === WEB2) {
    NextRouter.push(transformedDealLink);
  } else {
    updateUrl(transformedDealLink);
  }
};

const unexpectedError = (dispatch: AsyncDispatch, experience: Experience) => {
  dispatch(
    openModal({
      title: DEAL_UNEXPECTED_ERROR_TITLE,
      body: DEAL_UNEXPECTED_ERROR_BODY,
      cta: {
        text: DEAL_UNEXPECTED_ERROR_CTA,
        callback: () => {
          router.goToRoute(experience === WEB2 ? Paths.DEALSW2 : Paths.DEALS);
        }
      }
    })
  );
};

const dealNotAvailable = (dispatch: AsyncDispatch) => {
  dispatch(
    openModal({
      title: DEAL_NOT_AVAILABLE_TITLE,
      body: DEAL_NOT_AVAILABLE_BODY,
      cta: { text: DEAL_NOT_AVAILABLE_CTA }
    })
  );
};

const handleOpenLocalizationRail = (
  dispatch: AsyncDispatch,
  dealLink: string,
  isRelativeLink: boolean,
  experience: Experience
) => {
  dispatch(
    openLocalizationRail({
      handleAfterLocalizing: async () => {
        navigateToDealLink(
          dealLink,
          isRelativeLink,
          experience
        );
      }
    })
  );
};

const switchStores = (dispatch: AsyncDispatch, callback: () => void) => {
  dispatch(
    openModal({
      title: SWITCH_STORE_TITLE,
      body: SWITCH_STORE_BODY,
      cta: { text: SWITCH_STORE_CTA, callback },
      altCta: { text: 'NO, THANKS' }
    })
  );
};

const handleOccasionSwitch = (
  dispatch: AsyncDispatch,
  title: string,
  body: string,
  text: string,
  callback: () => void
) => {
  dispatch(
    openModal({
      title,
      body,
      cta: { text, callback },
      altCta: { text: 'NO, THANKS' }
    })
  );
};

const switchToCarryout = (dispatch: AsyncDispatch, callback: () => void) => {
  handleOccasionSwitch(
    dispatch,
    SWITCH_TO_CARRYOUT_TITLE,
    SWITCH_TO_CARRYOUT_BODY,
    SWITCH_TO_CARRYOUT_CTA,
    callback
  );
};
const switchToDelivery = (dispatch: AsyncDispatch, callback: () => void) => {
  handleOccasionSwitch(
    dispatch,
    SWITCH_TO_DELIVERY_TITLE,
    SWITCH_TO_DELIVERY_BODY,
    SWITCH_TO_DELIVERY_CTA,
    callback
  );
};

const redirectOrShowModal = (
  dispatch: AsyncDispatch,
  storeOffersDeal: boolean,
  switchOccasion: boolean,
  occasion: Occasion,
  dealLink: string,
  isRelativeLink: boolean,
  experience: Experience,
) => {
  const dealAvailable = storeOffersDeal && !switchOccasion;
  const deliveryOnlyDealAsCarryout = Boolean(
    occasion === Occasion.CARRYOUT && storeOffersDeal && switchOccasion
  );
  const carryoutOnlyDealAsDelivery = Boolean(
    occasion === Occasion.DELIVERY && storeOffersDeal && switchOccasion
  );
  const unavailableDealAsCarryout = Boolean(
    occasion === Occasion.CARRYOUT && !storeOffersDeal
  );
  const unavailableDealAsDelivery = Boolean(
    occasion === Occasion.DELIVERY && !storeOffersDeal
  );
  const openLocalizationOnSwitch = () =>
    handleOpenLocalizationRail(
      dispatch,
      dealLink,
      isRelativeLink,
      experience
    );

  if (dealAvailable)
    return navigateToDealLink(
      dealLink,
      isRelativeLink,
      experience
    );
  if (deliveryOnlyDealAsCarryout)
    return switchToDelivery(dispatch, openLocalizationOnSwitch);
  if (carryoutOnlyDealAsDelivery)
    return switchToCarryout(dispatch, openLocalizationOnSwitch);
  if (unavailableDealAsCarryout)
    return switchStores(dispatch, openLocalizationOnSwitch);
  if (unavailableDealAsDelivery) return dealNotAvailable(dispatch);
};

export const navigateToDealIfExists =
  (
    dealLink: string,
    dealName: string,
    isRelativeLink: boolean,
    experience: Experience,
    localizationToken: string
  ): AsyncDispatch =>
  async (dispatch, getState) => {
    const state = getState && getState();
    const localization = state.domain.localization;
    const storeNumber = localization.localizedStore;
    const occasion = localization.localizedOccasion;
    try {
      const { storeOffersDeal, switchOccasion = false } =
        await dealService.doesStoreOfferDeal(
          storeNumber,
          dealName,
          localizationToken
        );

      redirectOrShowModal(
        dispatch,
        storeOffersDeal,
        switchOccasion,
        occasion,
        dealLink,
        isRelativeLink,
        experience
      );
    } catch (error) {
      unexpectedError(dispatch, experience);
    }
  };
