import { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { AnalyticsDataModel } from '@/rootStateTypes';
import { useApplicationInitialized } from '@/hooks/useApplicationInitialized';
import useAnalytics from '@/dataAnalytics/hooks/useAnalytics';

const useScrollTrackingForAnalytics = (
  helperFunction: (label: string, data: any, options?: boolean) => Partial<Record<any,any>>, 
  label: string,
  promo: ((model: AnalyticsDataModel) => Record<any, any>) | null,
  threshold?: number, 
  loading?: boolean,
  lineup = false
) => {
  const [pageLoadAnalyticsPushed, setPageLoadAnalyticsPushed] = useState(false);
  const analytics = useAnalytics();
  const { analyticsDataModel } = analytics;
  const appInitialized = useApplicationInitialized();

  const { ref, inView, entry } = useInView({
    triggerOnce: true,
    threshold: threshold || 0.75
  });

  useEffect(() => {
    if (inView && appInitialized && !pageLoadAnalyticsPushed && !loading) {
      const promoData = promo && promo(analyticsDataModel);

      analytics.push(()=>
        helperFunction(label, promoData, lineup),
      );
      setPageLoadAnalyticsPushed(true);
    }
  }, [entry, appInitialized, loading]);

  return ref;
};

export const usePageLoadedAndInView = () => {
  const [isPageInView, setPageInView] = useState(false);
  const appInitialized = useApplicationInitialized();

  const { ref, inView, entry } = useInView({ triggerOnce: true });

  useEffect(() => {
    if (inView && appInitialized && !isPageInView) {
      setPageInView(true);
    }
  }, [entry, appInitialized]);

  return {
    ref,
    isPageInView
  };
};

export default useScrollTrackingForAnalytics;
