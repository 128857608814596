import { useSelector } from 'react-redux';
import { RootState } from '@/rootStateTypes';
import { AuthState } from '@/auth/userStates';

export function useApplicationInitialized(): boolean {
  return useSelector((state : RootState) => {
    const isUserDomainInitialized = state.domain.user.loginStatus !== AuthState.UNKNOWN;
    const { localizationInitialized } = state.domain.localization;
    return (localizationInitialized && isUserDomainInitialized);
  });
}
