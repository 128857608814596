import cookie from 'next-cookies';
import { GetServerSidePropsContext } from 'next';

const cookieName = 'gql_version';

function getGqlVersionToOverride(ctx: GetServerSidePropsContext): string | undefined {
  return cookie(ctx)[cookieName];
}

export default getGqlVersionToOverride;
