import React from 'react';
import { GetServerSideProps } from 'next';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import initializePage from '../app/common/pageSetup';
import Header from '../app/header';
import CenteredContainer from '../app/common/CenteredContainer';
import Footer from '../app/footer';
import HomePage from '../app/homepage/Homepage/HomePage';
import { openRail } from '@/rail/slices/Rail.slice';
import { RailType } from '@/rail/slices/Rail.slice.types';
import { openLocalizationRail } from '@/localization/actions';
import { RailUrlStatus } from '@/rail/railContent/signInRail/constants';

interface HomeProps {
  auth: boolean;
  railStatus?: string;
}

const Home = ({ auth, railStatus }: HomeProps): JSX.Element => {
  const dispatch = useDispatch();
  const router = useRouter();
  const resetCode = router.query?.reset_code as string;

  if (auth || railStatus === RailUrlStatus.RESET || resetCode) {
    dispatch(openRail(RailType.SIGN_IN));
  }

  if (router.query?.local) {
    dispatch(openLocalizationRail());
  }

  return (
    <>
      <Header />
      <CenteredContainer>
        <HomePage />
      </CenteredContainer>
      <Footer />
    </>
  );
};

export const getServerSideProps: GetServerSideProps = async (ctx) => initializePage(ctx, 'homepage');

export default Home;
