import { GetServerSidePropsContext } from 'next';
import { v4 as uuid } from 'uuid';
import Cookies from 'cookies';
import { OPTIMIZELY_PROVIDED_USER_ID_COOKIE, WEB2_OPTIMIZELY_USER_ID_COOKIE } from './constants';

/**
 * Get the userId that will be provided to the Optimizely client to identify users.
 *
 * First attempt to read from the `optimizelyWeb2UserId` cookie if it is present, then fallback to the
 * the `optimizelyEndUserId` cookie which is provided by optimizely. If neither cookies are present
 * generate a new uuid to represent the user id. Additionally set the `optimizelyWeb2UserId` cookie if
 * it is not present and have it expire 180 days from now.
 *
 * Note: we should never override the `optimizelyEndUserId` cookie.
 */
export const getOptimizelyUserId = (
  req: GetServerSidePropsContext['req'],
  res: GetServerSidePropsContext['res']
): string => {
  const cookies = new Cookies(req, res);
  const userIdCookie = cookies.get(WEB2_OPTIMIZELY_USER_ID_COOKIE);
  const optimizelyProvidedUserIdCookie = cookies.get(OPTIMIZELY_PROVIDED_USER_ID_COOKIE);

  let id = userIdCookie;
  if (!id) {
    id = optimizelyProvidedUserIdCookie || uuid();
    cookies.set(WEB2_OPTIMIZELY_USER_ID_COOKIE, id, {
      maxAge: 180 * 24 * 60 * 60 * 1000, // 180 days
      domain: 'pizzahut.com'
    });
  }
  return id;
};
