/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import cookie from 'next-cookies';
import {
  GQL_DECORATION_COOKIE_NAME,
  GQL_DECORATION_COOKIE_VALUE_IF_DISABLED,
  GQL_SSR_COOKIE_NAME,
  GQL_SSR_COOKIE_VALUE_IF_DISABLED
} from './constants';

export function isGqlSsrDisabled(ctx: any) : boolean {
  return cookie(ctx)[GQL_SSR_COOKIE_NAME] === GQL_SSR_COOKIE_VALUE_IF_DISABLED;
}

export function isGqlClientSideDecorationDisabled(ctx: any) : boolean {
  return cookie(ctx)[GQL_DECORATION_COOKIE_NAME] === GQL_DECORATION_COOKIE_VALUE_IF_DISABLED;
}
