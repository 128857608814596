import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { BoxProps } from '@material-ui/core';
import clsx from 'clsx';

import colors from '@/common/colors';
import Badge from './Badge';

const useStyles = makeStyles({
  newBadge: {
    borderColor: colors.gray400
  }
});

const NewBadge = (props: BoxProps): JSX.Element => {
  const { className } = props;
  const classes = useStyles();

  return (
    <Badge
      {...props}
      text="NEW"
      dataTestId="new-badge"
      className={clsx(classes.newBadge, className)}
      alert
    />
  );
};

export default NewBadge;
