import React from 'react';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import useStyles from './styles';
import AnchorLink from '../../common/AnchorLink';

interface HeaderProps extends LinkContent {
  heading: string;
  headerTestId?: string;
  linkTestId?: string;
  dataAnalyticsCategory?: string;
  dataAnalyticsAction?: string;
}

const SectionHeader = (
  {
    heading, link, linkTitle, linkType, linkDisplayText, external, headerTestId, linkTestId,
    dataAnalyticsCategory, dataAnalyticsAction
  }: HeaderProps
) => {
  const {
    root, headerText, linkText, headerIcon
  } = useStyles();

  return (
    <div className={root}>
      <h1 data-testid={headerTestId} className={headerText}>{heading}</h1>
      <AnchorLink
        link={link}
        tabIndex={0}
        external={external}
        linkTitle={linkTitle}
        linkType={linkType}
        testId={linkTestId}
        className={linkText}
        dataAnalyticsCategory={dataAnalyticsCategory}
        dataAnalyticsAction={dataAnalyticsAction}
      >
        <>
          {linkDisplayText}
          <ArrowForwardIosIcon fontSize="small" className={headerIcon} />
        </>
      </AnchorLink>
    </div>
  );
};

export default SectionHeader;
