import { Product } from '@pizza-hut-us-development/client-core';
import { DealRecipe } from '@/builders/deals/slice/dealTypes';

const TAVERN_CATEGORY_ID = '105';

export const isCyo = (product: Product | DealRecipe) => product.isCYO || product.name.match(/(create your own)/i);
export const isMeltProduct = (name: string) => name?.toLowerCase().includes('melt');
export const isTavern = (product: Product) => (
  product.subcategories?.some((cat) => cat.subcategoryID === TAVERN_CATEGORY_ID)
);

export interface PizzaMenuCategories {
  cyoPizza?: Product;
  tavernPizzas: Product[];
  signaturePizzas: Product[];
}

export function categorizePizzaProducts(products: Product[], tavernPizzaIds: string[]): PizzaMenuCategories {
  let cyoPizza;
  const signaturePizzas: Product[] = [];
  const tavernPizzas: Product[] = [];
  products.forEach((product) => {
    if (isCyo(product)) {
      cyoPizza = product;
    } else if (isTavern(product) || tavernPizzaIds.includes(product.id)) {
      tavernPizzas.push(product);
    } else {
      signaturePizzas.push(product);
    }
  });
  return {
    cyoPizza,
    signaturePizzas,
    tavernPizzas
  };
}
