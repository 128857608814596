import { pickBy } from 'remeda';
import getCmsEnvIdToOverride from './cmsEnv';
import {
  getLocalizationTokenDetail
} from '@/localization/localizationToken';
import { getUserAuthentication } from '@/header/unifiedAuth';
import getSessionRememberCart from '@/header/cart/angularCart/getSessionRememberCart';
import getGqlUrlToOverride from './gqlEnv';
import getGqlVersionToOverride from './gqlVersion';
import standaloneLocalizationService from '@/services/localizationService/standaloneLocalizationService';
import getUserExperience from "./userExperience";
import getSalesExperience from '@/salesExperience/getSalesExperience';
import { GetServerSidePropsContext } from 'next';
import getEnableCartDiagnostics from './advancedCartDiagnostics';
import { getGoogleTagManagerFeatureFlag } from './getGoogleTagManagerFeatureFlag';
import { getYumAuthRefreshData } from './getYumAuth';

function stripUndefined(withUndefinedValues: any): any {
  return pickBy(withUndefinedValues, (value: any) => value !== undefined);
}

const fetchCookieData = async (ctx: GetServerSidePropsContext) => {
  const {
    token: localizationToken,
    source: localizationTokenSource
  } = await standaloneLocalizationService.getToken(ctx);

  const cmsEnvId = getCmsEnvIdToOverride(ctx);
  const gqlUrl = getGqlUrlToOverride(ctx);
  const gqlVersion = getGqlVersionToOverride(ctx);
  const localizationTokenDetails = getLocalizationTokenDetail(localizationToken);
  const userAuthentication = getUserAuthentication(ctx);
  const sessionRememberCart = getSessionRememberCart(ctx);
  const userExperience = getUserExperience(ctx);
  const salesExperience = getSalesExperience(ctx);
  const enableAdvancedCartDiagnostics = getEnableCartDiagnostics(ctx);
  const googleTagManagerFeatureEnabled = getGoogleTagManagerFeatureFlag(ctx);
  const yumAuthRefreshData = getYumAuthRefreshData(ctx);

  const featuresAndConfig = {
      userAuthentication,
      localizationToken,
      cmsEnvId,
      gqlUrl,
      gqlVersion,
      localizationTokenSource,
      userExperience,
      salesExperience,
      enableAdvancedCartDiagnostics,
      googleTagManagerFeatureEnabled,
      yumAuthRefreshData
  };
  const localizationSessionAndFeatures = { ...localizationTokenDetails, ...sessionRememberCart, ...featuresAndConfig };
  return stripUndefined(localizationSessionAndFeatures);
};

export default fetchCookieData;
