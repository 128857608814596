import React from 'react';
import useStyles from './styles';

const SkipLinks = () => {
  const classes = useStyles();
  return (
    <p className={classes.container}>
      <a tabIndex={0} href="#menu">skip to Menu</a>
      <a tabIndex={0} href="#content">skip to Content</a>
      <a tabIndex={0} href="#footer">skip to Footer</a>
    </p>
  );
};

export default SkipLinks;
